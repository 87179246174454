import { useState, useEffect, useRef, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import HomeIcon from './HomeIcon';
import styles from './styles.module.css';
// eslint-disable-next-line
// @ts-ignore
import { navigateToUrl } from 'single-spa';
// eslint-disable-next-line
// @ts-ignore
import { isLoggedInState, breadcrumbsState, getWidget } from '@sales-i/utils';
import Cookies from 'universal-cookie';

const cookies = new Cookies(null, { path: '/' });

export default function Breadcrumbs() {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [widgetData, setWidgetData] = useState(null);
  const location = useLocation();
  const isMobile = cookies.get('platform') === 'mobile';
  const hasWidget = useRef(false);

  useEffect(() => {
    const ilsrx = isLoggedInState.subscribe(l => setLoggedIn(l));
    return () => { ilsrx.unsubscribe(); };
  }, [loggedIn]);

  const navHome = () => navigateToUrl('/');

  const replaceBreadcrumbName = useRef({
    crm: 'CRM',
    enquiry: 'Enquiries'
  });

  const definedEntities = useRef([
    'customers',
    'prospects',
    'contacts',
    'interactions',
    'opportunities',
    'posts',
    'alerts',
    'users',
    'roles'
  ]);

  const [crumbsState, setCrumbsState] = useState({});
  useEffect(() => {
    const bcsrx = breadcrumbsState.subscribe(bc => {
      setCrumbsState({ ...bc });
    });
    return () => { bcsrx.unsubscribe(); };
  }, []);

  useEffect(() => {
    const crumbs = [];
    let previousRoute = '';
    const parts = location.pathname.split('/');
    let previousPartIsEntity = false;
    let previousPart = '';
    parts.forEach((part) => {
      if (part !== '') {
        const route = `${previousRoute}/${part}`;
        previousRoute = route;
        previousPartIsEntity = definedEntities.current.includes(previousPart);
        let name = replaceBreadcrumbName.current[part] ?? getPartName(part);
        if (previousPartIsEntity) {
          name = crumbsState?.[previousPart]?.[part] || name;
        }
        previousPart = part;
        crumbs.push({
          name: name,
          path: route,
        });
      }
    });
    setBreadcrumbs(crumbs);
  }, [definedEntities, location, crumbsState, replaceBreadcrumbName, widgetData]);

  const getPartName = part => {
    if (hasWidget.current && widgetData && widgetData.name) {
      if (part === 'report') return widgetData.name;
    }
    const crumbParts = part.split('-');
    let partName = '';
    crumbParts.forEach((crumb, i) => {
      if (i > 0) partName += ' ';
      partName += `${crumb.substring(0,1).toUpperCase()}${crumb.substring(1)}`;
    });
    return partName;
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const dashboardId = searchParams.get('dashboard_id');
    const widgetId = searchParams.get('id');

    if (dashboardId && widgetId) {
      (async () => {
        const widget = await getWidget(dashboardId, widgetId);
        setWidgetData(widget); // Store the widget data in state
        hasWidget.current = true; // Set the flag to true when widget data is fetched
      })();
    }
  }, [location]);

  // turn off breadcrumbs for some cases
  if (breadcrumbs.find(b => b.name === 'Iframe')) return null;
  if (!loggedIn || isMobile) return null;
  if (breadcrumbs.length === 0) return null;

  return (
    <div className={styles.breadcrumbs}>
      <button title="Home" onClick={navHome} className={styles.homebtn}>
        <HomeIcon />
      </button>
      <span> &gt; </span>
      {
        breadcrumbs.map((crumb, index) => (
          <Fragment key={crumb.path}>
            <Breadcrumb
              name={crumb.name}
              path={crumb.path}
              disabled={index === breadcrumbs.length - 1}
            />
            {
              index !== breadcrumbs.length - 1 &&
                            <span> &gt; </span>
            }
          </Fragment>
        ))
      }
    </div>
  );
}