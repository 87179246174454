import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import Breadcrumbs from './components/Breadcrumbs';

const router = createBrowserRouter([
  {
    path: '/*',
    element: <Breadcrumbs />,
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
