// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SA50PBfnU26iG411aWd2 {
    display: flex;
    align-items: center;
    gap: var(--spacing-1);
    flex-wrap: wrap;
    padding: var(--spacing-2);
}

.PONc3334Wg2fIKW98fEY {
    color: var(--colour-utility-action);
    font-style: italic;
    font-weight: var(--font-weight-medium);
}

.PONc3334Wg2fIKW98fEY:last-child {
    font-style: normal;
}

.PONc3334Wg2fIKW98fEY:disabled {
    color: var(--colour-utility-focus);
}

.U4k0iSd5bpB6WG46iAv_ {
    height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,mCAAmC;IACnC,kBAAkB;IAClB,sCAAsC;AAC1C;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".breadcrumbs {\n    display: flex;\n    align-items: center;\n    gap: var(--spacing-1);\n    flex-wrap: wrap;\n    padding: var(--spacing-2);\n}\n\n.breadcrumb {\n    color: var(--colour-utility-action);\n    font-style: italic;\n    font-weight: var(--font-weight-medium);\n}\n\n.breadcrumb:last-child {\n    font-style: normal;\n}\n\n.breadcrumb:disabled {\n    color: var(--colour-utility-focus);\n}\n\n.homebtn {\n    height: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs": `SA50PBfnU26iG411aWd2`,
	"breadcrumb": `PONc3334Wg2fIKW98fEY`,
	"homebtn": `U4k0iSd5bpB6WG46iAv_`
};
export default ___CSS_LOADER_EXPORT___;
