import styles from './styles.module.css';
// eslint-disable-next-line
// @ts-ignore
import { navigateToUrl } from 'single-spa';

export default function Breadcrumb({ name, path, disabled }) {
  const navToPath = () => navigateToUrl(`${path}`);

  return (
    <button
      onClick={navToPath}
      disabled={disabled}
      className={styles.breadcrumb}
    >
      {name}
    </button>
  );
}